import React, { FC } from "react";
import FadeInOut from "../components/Animations/FadeInOut/FadeInOut";
import Button from "../components/Button/Button";
import LineReveal from "../components/Text/LineReveal/LineReveal";

export interface WrongAdressProps {}

const WrongAdress: FC<WrongAdressProps> = () => {
  return (
    <FadeInOut>
      <div style={{ height: "100vh", display: "grid", placeItems: "center" }}>
        <div>
          <LineReveal renderAs="h1" text="Sorry, this page doesn't exist" />
          <LineReveal
            renderAs="p"
            text="Przykro nam, ale nie mogliśmy znaleźć wyszukiwanej przez Ciebie strony."
          />
        </div>
        <Button
          url="/"
          text="Return home"
          style={{ position: "absolute", bottom: "20vh" }}
        />
      </div>
    </FadeInOut>
  );
};
export default WrongAdress;
